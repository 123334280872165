import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import Theme4 from "../themes/theme4";
import Download from "../themes/download";


export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Theme4} />
            <Route exact path="/form/:formId?" component={Download} />

          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
